<template>
	<section class="catalog">
		
		<div class="catalog__filter ">
			<component-section-filter></component-section-filter>
		</div>
		<div class="product-list">
			<div v-bind:class="{ show: loading }" class="component-loading"></div>
			<div class="animated-page">
				<div class="catalog__grid" v-if=!isShowSearch>
					<div v-bind:data-page-size=navPageSize v-bind:class=viewMode class="product-grid" v-bind:data-page=navPageNum>
						
						<component-list-item
						  v-for="productItem in products" 
						  v-bind:can_delete="can_delete" 
						  v-bind:data-page="navPageNum" 
						  v-bind:key="'list_product_'+productItem.id" 
						  v-bind="productItem"
						></component-list-item>
						
					</div>
				</div>
				
				<div v-if=isShowSearch class="catalog-search animated fadeIn">
					<div v-if="!emptyResult" class="catalog__grid">
						<div v-bind:data-page-size=navPageSize v-bind:class=viewMode class="product-grid" v-bind:data-page=navPageNum>
							
							<component-list-item
							  v-for="productItem in searchResult" 
							  v-bind:can_delete="can_delete" 
							  v-bind:data-page="navPageNum" 
							  v-bind:key="'search_product_'+productItem.id" 
							  v-bind="productItem"
							></component-list-item>
							
						</div>
					</div>
					<div v-else class="empty-result">
						<h4 v-html="emptyMess"></h4>
					</div>
				</div>
				
			</div>
			
		</div>
	
		
		<div class="b-content__footer" v-if=!isShowSearch>
			<component-pagination></component-pagination>
		</div>
		
	</section>
</template>

<script>
import filterComponent from '../components/filterComponent';
import paginationComponent from '../components/paginationComponent';
import listItemComponent from '../components/listItemComponent';

export default {
	name: 'component-section',
	data: function() {
		return {
			loading: true,
			showMore: false,
			isShowSearch: false,
			section: [],
			showClass: "fadeInUp showed",
			animationClass: {
				out: "slide-fade-page", 
				in: ""
			},
			section_id: this.$route.params.section_id,
			viewMode: "list-mode",
			navPageNum: this.$route.params.navPageNum || 1,
			navPageSize: this.$route.params.NavPageSize || 12,
			navPageCount: 1,
			searchProp: false,
			searchText: false,
			emptyResult: false,
			navPageCount: 1,
			navParam: {},
			products: [],
			searchResult: [],
			emptyMess: 'Поиск не дал результатов...',
			sort: {
				by: "",
				order: ""
			}
		}
	},
	components: {
		'component-section-filter': filterComponent,
		'component-pagination': paginationComponent,
		'component-list-item': listItemComponent,
	},
	computed:{
		can_delete(){
			var self = this;
			if(self.$parent.can_delete === true) return true;
			else return false;
		},
		route_params(){
			return this.$route.params;
		},
	},
	mounted: function(){
		var self = this;
		var app = self.$root;
		var savedView = localStorage.getItem("view");
		if(savedView == "list-mode" || savedView == "grid-mode") self.viewMode = savedView;
		
		
		self.loadSection(self);
		self.$root.$on('settingsUpdated', self.loadSection);
	},
	watch: {
		sort: function(newVal, oldVal){
			var self = this;
			localStorage.setItem("sort", JSON.stringify(newVal));
			
		},
		viewMode: function(newVal, oldVal){
			localStorage.setItem("view", newVal);
		},
		route_params: function (newVal, oldVal) {
			var self = this;
			self.loading = true;
			self.section_id = newVal.section_id;
			self.navPageNum = newVal.navPageNum || 1;
			
			self.$root.menuOpened = false;
			
			if(self.showMore) {
				self.loadSection();
			}
			else{
				$(".animated-page").removeClass(self.animationClass.out).removeClass(self.animationClass.in).addClass(self.animationClass.out);
				setTimeout(function(){
					self.loadSection();
				}, 10);
			}
		},
		showMore: function(){
			var self = this;
			var urlData = {
				SECTION_ID: self.section_id,
				PAGEN_1: self.navPageNum,
			};
			
		},
		searchProp: function (newVal, oldVal) {
			var self = this;
			self.searchText = "";
			self.searchResult = [];
			$(".articul_select_control input").val("").parent().removeClass("not-empty");
		},
	},
	methods:{
		focusSearchInput(propCode){
			var self = this;
			self.searchProp = propCode;
			self.showSearch(propCode);
			
		},
		blurSearchInput(propCode){
			var self = this;
			if(self.searchText.length <= 0) self.hideSearch(propCode);
		},
		showSearch(propCode){
			var self = this;
			self.isShowSearch = true;
		},
		hideSearch(propCode){
			var self = this;
			self.isShowSearch = false;
			self.$nextTick(function () {
				self.showAnimateItems();
			})
		},
		searchClear(e){
			var self = this,
				input = $(e.target).parent().find("input");
			
			input.val("").parent().removeClass("not-empty");
			self.searchProp = false;
			self.hideSearch();
		},
		searchItems(e){
			var self = this;
			var text = e.target.value;
			self.searchText = text;
			
			if(self.searchText.length > 0) $(e.target).parent().addClass("not-empty");
			else $(e.target).parent().removeClass("not-empty");
			
			if(!!self.timer) clearTimeout(this.timer);
			self.timer = setTimeout(function(){
				if(self.searchText.length > 2){
					self.loading = true;
					var baseUrl = (self.$route.meta.baseUrl ? self.$route.meta.baseUrl : self.$route.path),
						filterProperty = ("%PROPERTY_" + self.searchProp),
						searchFilter = {"!=PROPERTY_ARCHIVE": "1", IBLOCK_ID: 56},
						urlData = {
							filter: searchFilter,
							size: 10, 
							baseUrl: baseUrl,
							SORT: self.sort.by,
							ORDER: self.sort.order,
						};
						searchFilter[filterProperty] = self.searchText;
					$.getJSON("/data/components/sectionFiltered.php", urlData, function(data){
						
						self.emptyResult = data.items.length <= 0;
						self.loading = false;
						self.searchResult = data.items;
						
						self.$nextTick(function () {
							self.showAnimateItems();
						})
						
					})
				}
			}, 500);
			
		},
		setSort: function(newSort){
			var self = this;
			if(!self.loading){
				self.sort = newSort;
				self.loading = true;
				$(".animated-page").removeClass(self.animationClass.out).removeClass(self.animationClass.in).addClass(self.animationClass.out);
				setTimeout(function(){
					self.loadSection();
				}, 10);
			}
		},
		loadSection: function(){
			var self = this;
			var storedSort = localStorage.getItem("sort");
			if(storedSort != "null" && storedSort != null && storedSort != undefined) {
				storedSort = JSON.parse(storedSort);
				self.sort = storedSort;
			}
			
			self.loading = true;
			
			var urlData = {
				SECTION_ID: self.section_id,
				PAGEN_1: self.navPageNum,
				SORT: self.sort.by,
				ORDER: self.sort.order,
				path: self.$route.path
			};
			
			if(self.$route.meta.baseUrl) urlData.baseUrl = self.$route.meta.baseUrl;
			
			$.getJSON("/data/components/section.php", urlData, function(data){
				self.loading = false;
				self.navParam = data.nav;
				self.navPageCount = data.nav.NavPageCount;
				
				var newData = [];
				if(!self.showMore){
					for(var i in data.items) newData.push(data.items[i]);
					self.products = newData;
				}else{
					for(var i in data.items) newData.push(data.items[i]);
					self.products = self.products.concat(newData);
				}
				
				
				self.$parent.breadcrumbs = data.section.path;
				self.$parent.sectionData = self.sectionData = data;
				self.$root.catalog.section = data;
				self.$root.catalog.filterData = data.filterData;
				self.$root.catalog.activeSections = data.section.active_sections;
				
				self.$emit('sectionLoaded', data);
				
				self.$nextTick(function () {
					self.showAnimateItems();
					self.$root.$emit('triggerScroll');
				})
				
				if($(".animated-page").hasClass(self.animationClass.out)){
					$(".animated-page").removeClass(self.animationClass.out).addClass(self.animationClass.in);
					setTimeout(function(){
						$(".animated-page").removeClass(self.animationClass.in);
					}, 200);
				}
			});
		},
		showAnimateItems: function(){
			var self = this,
				pageSize = parseInt(self.navParam.NavPageSize),
				curPage = parseInt(self.navPageNum),
				totalItemsCnt = $(".product-grid__item").length,
				pagesShowed = totalItemsCnt/pageSize,
				cntLastLoaded = parseInt(totalItemsCnt % pageSize);
			var	nextIndex = (pagesShowed - 1) * pageSize;
				
			if(!self.showMore) nextIndex = 0;
			var tmout = 70;
			$(".product-grid__item").each(function(){
				var el = $(this);
				if(el.index() >= nextIndex){
					setTimeout(function(){
						el.addClass(self.showClass);
					}, tmout * (el.index() - nextIndex));
				}
			});
			self.showMore = false;
		},
	},
};
</script>

<style scoped>
	.product-list{
		position:relative;
		z-index: 11;
	}
	.product-list .component-loading{
		z-index: 10;max-height: 70vh;
		background-color: transparent;
	}
	.product-list .product-grid__item.animated {
		opacity: 0;
		-webkit-animation-duration: 0.3s;
		animation-duration: 0.3s;
	}
	.catalog-search{
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;    
		background: #f6f8fb;
		-webkit-animation-duration: 0.3s;
		animation-duration: 0.3s;
	}
</style>