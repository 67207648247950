import { render, staticRenderFns } from "./order_client.vue?vue&type=template&id=4b5ab7a0&"
import script from "./order_client.vue?vue&type=script&lang=js&"
export * from "./order_client.vue?vue&type=script&lang=js&"
import style0 from "./order_client.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bitrix/ext_www/cabinettest.feron.ru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b5ab7a0')) {
      api.createRecord('4b5ab7a0', component.options)
    } else {
      api.reload('4b5ab7a0', component.options)
    }
    module.hot.accept("./order_client.vue?vue&type=template&id=4b5ab7a0&", function () {
      api.rerender('4b5ab7a0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "custom/vue/pages/order_client.vue"
export default component.exports