<template>
	<div>
		<div v-bind:class="{ show: loading }" class="component-loading"></div>
		<div class="environ environ_hr">
			<div v-bind:class="{'hidden': $root.arUser.profiles.count == 1}" class="environ__group">
				<label class="environ__label">Активный договор</label>
				<div class="environ__select environ__select--order">
					<select class="select-profile select-profile-home select" name="">
						<option
							v-for="profile in $root.arUser.profiles.list"
							v-bind:value="profile.id"
							v-bind:class="{'active': profile.active}"
							v-bind:selected="profile.active"
							v-html="profile.props.ORDER_PROP_75.value"></option>
					</select>
				</div>
			</div>
			<div v-bind:class="{'hidden': activeProfile.stores.count == 1}" class="environ__group ">
				<label class="environ__label">Активный склад</label>
				<div class="environ__select">
					<select class="select-store select-store-home select" name="">
						<option
							v-for="store in activeProfile.stores.list"
							v-bind:value="store.ID"
							v-bind:class="{'active': store.selected}"
							v-bind:selected="store.selected"
							v-html="store.TITLE"></option>
					</select>
				</div>
			</div>
		</div>

    <h2>TEST</h2>

		<component-section></component-section>

	</div>

</template>

<script>
import sectionComponent from '../components/sectionComponent';

export default {
	name: 'SectionPageComponent',
	data: function () {
		var self = this;
		return {
			loading: false,
			name: "section",
			breadcrumbs: [],
			profiles: self.$root.arUser.profiles.list,
			section_id: 0,
		}
	},
	components: {
		'component-section': sectionComponent,
	},
	computed: {
		activeProfile() {
			var self = this;
			return self.$root.arUser.profiles.active;
		},
	},
	watch: {
		breadcrumbs: function (newVal, oldVal) {
			var self = this;
			self.$root.breadcrumbs = self.breadcrumbs;
		},
	},
	mounted: function(){
		var self = this;
		self.$root.breadcrumbs = self.breadcrumbs;

		self.$nextTick(function(){
			$('.select').select2({
				minimumResultsForSearch: -1,
				width: "100%",
				selectionCssClass: "select2-results__option--selected",
			}).on('select2:select', function (e) {
				setTimeout(function(){
					var selectedProfileId = $(".select-profile-home option:selected").attr("value");
					var selectedStoreId = $(".select-store-home option:selected").attr("value");

					self.$root.setActiveProfile(selectedProfileId, selectedStoreId);
					self.loading = true;
				}, 300);
			});
		})
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
