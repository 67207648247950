<template>
	<div id="cabinet-application">
		<header v-if="this.$root.arUser.authorized">
			<div class="container">
				<div class="header noPrint">
					<button id="hamburger" v-bind:class="{ 'is-open': $root.menuOpened }" v-on:click="this.$parent.toggleMenu">
						<i></i>
						<i></i>
						<i></i>
					</button>
					<router-link :to="'/'" class="header-logo">
						<svg width="220" height="36">
							<use xlink:href="/images/sprite.svg#logo"></use>
						</svg>
					</router-link>
					<div v-if="this.$root.arUser.authorized" class="header-search">
						<button class="header-search__trigger">
							<svg width="17" height="16">
								<use xlink:href="/images/sprite.svg#search"></use>
							</svg>
						</button>
						<div method="get" class="header-search__form" action="">

							<input type="text" v-bind:value="query" v-on:focus="quickSearch" v-on:input="quickSearch" placeholder="Искать товар по артикулу, названию, модели..." name="q" autocomplete="off" class="header-search__text">

							<button type="button" class="header-search__submit">
								<svg width="17" height="16">
									<use xlink:href="/images/sprite.svg#search"></use>
								</svg>
							</button>
						</div>
					</div>

					<div class="header-cut"></div>

					<router-link v-if="$root.arUser.loyalty" :to="'/bonus/'" class="header-bonus">
						<div class="header-bonus__label">активные бонусы</div>
						<div class="header-bonus__amount">
							<svg width="23" height="23">
								<use xlink:href="/images/sprite.svg#cashback"></use>
							</svg>
							<span v-html="$root.arUser.balls" class="header-bonus__value"></span>
						</div>
					</router-link>

					<template v-if="!this.$root.arUser.basketDisabled">
					<router-link v-if="this.$root.arUser.authorized" :to="'/cart/'" class="header-cart">
						<div class="header-cart__total-price">{{ this.$root.animatedPrice }} ₽</div>
						<div class="header-cart__icon">
							<svg class="svg-icon" width="23" height="25">
								<use xlink:href="/images/sprite.svg#header-basket"></use>
							</svg>
							<span v-if='this.$root.badges.cart' class="badge js-cart-badge">{{this.$root.badges.cart}}</span>
						</div>
					</router-link>
					</template>


					<router-link v-if="this.$root.arUser.authorized" :to="'/personal/'" class="header-user">
						<img v-bind:src="$root.arUser.avatar" alt="">
					</router-link>
				</div>
			</div>
		</header>
		<div class="container">
			<main v-if="this.$root.arUser.authorized" id="main">
				<div id="aside" v-bind:class="{ 'is-open': $root.menuOpened }" class="noPrint">
					<div class="absolute-menu">

						<component-menu></component-menu>

					</div>
				</div>
				<div id="content">
					<ul class="breadcrumbs noPrint">
						<li v-for="item in this.$parent.breadcrumbs">
							<router-link v-bind:to="item.link" v-html="item.name"></router-link>
						</li>
					</ul>
					<div class="page-content b-content b-content_comparison">
						<transition
							transitionMode="out-in"
							:name="this.$parent.transitionName"
							@beforeLeave="this.$parent.beforeLeave"
							@enter="this.$parent.enter"
							@afterEnter="this.$parent.afterEnter">
							<router-view></router-view>
						</transition>
					</div>
					<footer class="footer noPrint">
						<router-link :to="'/'" class="footer-logo">
							<svg width="220" height="36">
								<use xlink:href="/images/sprite.svg#logo"></use>
							</svg>
						</router-link>
						<div class="footer-col">
							<div>г. Москва, ул. Дорожная, 48</div>
							<div>Режим работы: 08:00–17:00</div>
						</div>
						<div class="footer-col">
							<div>+7 (499) 519-30-18</div>
							<div>mail@feron.ru</div>
						</div>
						<div class="footer-col">
							<div class="footer-social">
								<a href="">
									<svg width="21" height="21">
										<use xlink:href="/images/sprite.svg#soc-fb"></use>
									</svg>
								</a>
								<a href="">
									<svg width="21" height="21">
										<use xlink:href="/images/sprite.svg#soc-ig"></use>
									</svg>
								</a>
								<a href="">
									<svg width="21" height="21">
										<use xlink:href="/images/sprite.svg#soc-yt"></use>
									</svg>
								</a>
							</div>
							<div>2021 © Feron</div>
						</div>
					</footer>
				</div>
			</main>

			<main v-else id="main">
				<div id="full_content">
					<div class="page-content">
						<transition
							transitionMode="out-in"
							:name="this.$parent.transitionName"
							@beforeLeave="this.$parent.beforeLeave"
							@enter="this.$parent.enter"
							@afterEnter="this.$parent.afterEnter">
							<router-view></router-view>
						</transition>
					</div>
				</div>
			</main>

		</div>

		<div v-if="this.$root.arUser.is_admin" v-bind:class="{'show': isShowSettings}" id="settings-modal" class="noPrint">
			<div v-on:click="this.$root.showSettings" class="settings-btn"><img src="/images/settings.png" /></div>
			<form class="settings-content settings-form" id="settings_form">
				<div v-bind:class="{ show: loading }" class="component-loading"></div>
				<div class="settings-tabs">
					<div v-for="tab in this.$parent.arSettings" v-on:click="toggleSetingTab(tab)" v-bind:id="tab.id" v-bind:class="{'is-active': tab.opened}" class="settings-tab">
						<span>{{ tab.name }}</span>
					</div>
				</div>
				<div class="settings-tabs-content">
					<div v-for="tab in this.$parent.arSettings" v-bind:class="[{'is-open': tab.opened}, tab.id]" class="settings-tab-content">
						<div v-for="item in tab.items" v-bind:class="item.CLASS" class="setting-block">
							<div v-on:click="toggleSetingItem(item)" class="block-title">{{ item.NUM }}. <span>{{ item.NAME }}</span></div>
							<div v-if="item.TYPE == 'LIST'" v-bind:class="{ 'show': item.SHOW}" class="block-content" lang="ru">
								<select class="select-settings js-settings-select settings-element" v-bind:class="[item.CLASS, item.TYPE]" v-bind:name="item.CODE" v-bind:multiple="{'multiple': item.MULTIPLE}">
									<option v-for="option in item.VALUES" v-bind:selected="option.selected" v-bind:value="option.value">{{ option.name }}</option>
								</select>
							</div>
							<div v-if="item.TYPE == 'SORT_BLOCK'" v-bind:class="{ 'show': item.SHOW}" class="block-content" lang="ru">
								<ul v-bind:class="item.CLASS" class="settings-blocks-conainer">
									<li v-for="block in item.VALUES" class="ui-state-default" v-bind:key="'set_item_' + block.key">
										<input class="settings-element" type="hidden" v-bind:name="item.CODE" v-bind:value="block.key"/>
										<div class="settings-block-showed cat-filter">
											<div class="control_checkbox">
												<label>
													<input type="checkbox" :checked="block.checked" v-bind:name="block.CODE" v-bind:value="block.value">
													<i></i><span></span>
												</label>
											</div>
										</div>
										<div class="settings-block-icon" v-html="block.icon"></div>
										<div class="settings-block-name">{{ block.name }}</div>
										<div class="settings-block-link" v-html="block.link"></div>
									</li>
								</ul>
							</div>
							<div v-if="item.TYPE == 'IMAGES_BLOCK'" v-bind:class="{ 'show': item.SHOW}" class="block-content" lang="ru">
								<div v-for="img_item in item.VALUES" v-bind:value="img_item.value" class="settings_img_item">
									<label v-bind:for="img_item.id + '_slideImg'">
										<img v-bind:src="img_item.img" />
										<input v-bind:id="img_item.id + '_slideImg'" @change="$root.showImagePreview" type="file" v-bind:name="'loyalSlider_' + img_item.id" class="img-prop" />
									</label>
									<div class="slide_content">
										<input type="text" v-bind:name="item.CODE + '[' + img_item.id + '][text]'" v-bind:value="img_item.text" placeholder="Текст" class="setting-prop">
										<input type="text" v-bind:name="item.CODE + '[' + img_item.id + '][link]'" v-bind:value="img_item.link" placeholder="Ссылка" class="setting-prop">
										<input type="text" v-bind:name="item.CODE + '[' + img_item.id + '][btn_text]'" v-bind:value="img_item.btn_text" placeholder="Текст кнопки" class="setting-prop">
									</div>
								</div>

								<div class="settings_img_item img_item_add btn btn_transp" @click="addLoyalSlide">
									Добавить слайд
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="settings-footer">
					<div v-on:click="saveSetings" class="btn">Сохранить</div>
				</div>
			</form>
		</div>

		<component-popup-products
			v-bind:modalClass="'modal_analog'"
			v-bind:modalId="'modal-analog'"></component-popup-products>

		<component-popup-quick-view></component-popup-quick-view>

		<component-popup-import-excel></component-popup-import-excel>

		<component-popup-profiles></component-popup-profiles>

		<div id="quick-search-result" v-bind:class="{'show': searchOpened, 'visible': searchVisible}">
			<div class="search-header">
				<button class="modal__close" v-on:click="closeSearch"></button>
				<div v-bind:class="{ show: loadingSearch }" class="component-loading"></div>
				<div class="search-header-text" v-html="searchTest"></div>
				<div v-bind:class="{ show: !loadingSearch }" class="search-header-count" v-html="countTest"></div>
			</div>
			<div class="product-list">

				<div v-bind:class="{ show: loadingSearch }" class="component-loading"></div>

				<div class="animated-page">
					<div class="catalog__grid">
						<div class="product-grid list-mode">

							<component-list-item
							  v-for="productItem in searchItems"
							  v-bind:can_delete="can_delete"
							  v-bind:data-page="navPageNum"
							  v-bind:key="'search_product_'+productItem.id"
							  v-bind="productItem"
							></component-list-item>

						</div>
					</div>
				</div>
			</div>
			<div v-bind:class="{'hidden': searchItems.length == 0}" class="b-content__footer">
				<component-pagination></component-pagination>
			</div>
		</div>

		<div id="modal-overlay"></div>
	</div>
</template>

<script>
import menuComponent from './components/menuComponent';
import popupProductListComponent from './components/popupProductListComponent';
import popupQuickViewComponent from './components/popupQuickViewComponent';
import popupImportExcelComponent from './components/popupImportExcelComponent';
import popupProfilesComponent from './components/popupProfilesComponent';
import listItemComponent from './components/listItemComponent';
import paginationComponent from './components/paginationComponent';
import select2 from 'select2';
import '/node_modules/select2/dist/js/i18n/ru.js';
import SumoSelect from 'sumoselect';

import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/sortable';

import 'morris/morris.js';
import 'morris/morris.css';

export default {
	name: 'home',
	data: function () {
		var self = this;
		return {
			menuOpened: self.$root.menuOpened,
			arSettings: self.$parent.arSettings,
			loading: true,
			showMore: false,
			showClass: "fadeInUp showed",
			loadingSearch: false,
			searchOpened: false,
			searchVisible: false,
			can_delete: false,
			searchTest: 'Ищем ',
			query: "",
			timer: null,
			typeTimer: null,
			navPageNum: self.$route.params.navPageNum || 1,
			navPageSize: self.$route.params.NavPageSize || 12,
			navPageCount: 1,
			NavRecordCount: 0,
			navParam: {},
			searchItems: [],
		}
	},
	computed:{
		isShowSettings(){ return this.$root.isShowSettings; },
		route_params(){return this.$route;},
		pagePath(){return this.$route.pagePath;},
		countTest(){ return parseInt(this.NavRecordCount) > 0 ? "Найдено: <span>" + this.NavRecordCount + "</span>" : ""; },
	},
	components: {
		'component-menu': menuComponent,
		'component-popup-products': popupProductListComponent,
		'component-popup-quick-view': popupQuickViewComponent,
		'component-popup-import-excel': popupImportExcelComponent,
		'component-popup-profiles': popupProfilesComponent,
		'component-list-item': listItemComponent,
		'component-pagination': paginationComponent,
	},
	mounted: function () {
    console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAA')
		var self = this;
		self.$root.initWS();
		self.$root.$on('wsCommand', self.onWebSocketCommand);

		$(".search-header").click(function(e){
			/*
			if($(e.target).hasClass(".modal__close")) $(".header-search__text").blur();
			else $(".header-search__text").focus();
			*/
		})

		var cats = self.$root.arUser.fields.UF_CATS;
		if ( cats.indexOf( 'i:2;s:1:"N";' ) !== -1 )
		{
			self.$root.arUser.basketDisabled = true;
		}
	},
	methods:{
		closeSearch: function(e){
			var self = this;
			self.searchTest = '';
			self.searchVisible = false;

			self.hideAnimateItems();

			setTimeout(function(){
				self.searchOpened = false;
				self.searchItems = [];
			}, 500);
		},
		quickSearch: function(e){
			var self = this;
			self.loadingSearch = true;
			self.searchOpened = true;
			self.query = e.target.value;

			if(e.type != "focus") {
				self.hideAnimateItems();
				if(self.$route.query.page){
					self.$router.push(self.$route.path);
				}
			}

			if(!!self.timer) clearTimeout(this.timer);
			if(self.query.length > 1){
				self.searchTest = 'Ищем "<span>' + self.query + '</span>" .';

				if(!!self.typeTimer) clearInterval(self.typeTimer);
				self.typeTimer = setInterval(function(){
					self.searchTest = self.searchTest + " ."
				}, 500);
				self.timer = setTimeout(function(){
					self.loadSearchResult();
				}, 500);

			}else{
				self.typeResultText('Введите поисковой запрос');
				setTimeout(function(){
					self.searchItems = [];
				}, 500);
				//
				self.loadingSearch = false;
				self.NavRecordCount = 0;
			}
		},
		loadSearchResult: function(){
			var self = this;
			var postData = {
				ajax_call: "y",
				q: self.query,
				PAGEN_1: self.navPageNum,
				baseUrl: "?page=",
			};

			$.post("/data/components/search.php", postData, function(json){

				var json = JSON.parse(json);
				self.navParam = json.nav;
				self.navPageCount = json.nav.NavPageCount;
				self.NavRecordCount = json.nav.NavRecordCount;

				var newData = [];
				if(!self.showMore){
					for(var i in json.items) newData.push(json.items[i]);
					self.searchItems = newData;
				}else{
					for(var i in json.items) newData.push(json.items[i]);
					self.searchItems = self.searchItems.concat(newData);
				}

				if(!self.searchItems.length) {
					self.searchOpened = true;
				}
				self.$emit('sectionLoaded', json);
				self.$nextTick(function () {
					var scrollEl = $("#quick-search-result .page" + self.navPageNum);
					if(scrollEl.length) $("#quick-search-result").scrollTop(scrollEl.position().top);
					else $("#quick-search-result").scrollTop(0);

					self.showAnimateItems();
					self.loadingSearch = false;

					self.timer = setTimeout(function(){
						clearInterval(self.typeTimer);
						self.typeResultText('Результаты поиска по запросу "<span>' + self.query + '</span>"');
						if(self.searchItems.length == 0)
							self.typeResultText('По запросу "<span>' + self.query + '</span>" ничего не найдено');
					}, 1000);
				})
			})
		},
		typeResultText: function(text){
			var self = this;
			self.searchTest = "";
			var i = 0;

			if(!!self.typeTimer) clearInterval(self.typeTimer);

			self.typeTimer = setInterval(function(){
				self.searchTest += text[i];
				if (++i >= text.length) clearInterval(self.typeTimer)
			}, 10);
		},
		hideAnimateItems: function(){
			var self = this;
			$("#quick-search-result .product-grid__item").addClass("fadeOutDown");
		},
		showAnimateItems: function(){
			var self = this,
				pageSize = parseInt(self.navParam.NavPageSize),
				curPage = parseInt(self.navPageNum),
				totalItemsCnt = $(".product-grid__item").length,
				pagesShowed = totalItemsCnt/pageSize,
				cntLastLoaded = parseInt(totalItemsCnt % pageSize);
			var	nextIndex = (pagesShowed - 1) * pageSize;

			if(!self.showMore) nextIndex = 0;
			var tmout = 70;
			$("#quick-search-result .product-grid__item").each(function(){
				var el = $(this);
				if(el.index() >= nextIndex){
					setTimeout(function(){
						el.addClass(self.showClass);
					}, tmout * (el.index() - nextIndex));
				}
			});
			self.showMore = false;
		},
		onWebSocketCommand: function(data){
			var self = this;
			if(data.command == "user_list_action" && data.params.list && data.params.list_data){
				self.$root.arUser[data.params.list] = data.params.list_data;
			}
		},
		addLoyalSlide: function(e){
			var btn = $(e.target);
			var self = this;
			var newCnt = $(".img_item_new").length;
			var html = '<div class="settings_img_item img_item_new">' +
							'<label for="' + newCnt + '_slideImg">' +
								'<img src="" />' +
								'<input id="' + newCnt + '_slideImg" onchange="app.$root.showImagePreview" type="file" name="loyalSlider_' + newCnt + '" class="img-prop" />' +
							'</label>' +
							'<div class="slide_content">' +
								'<input type="text" name="newSettings[SLIDER][' + newCnt + '][text]" placeholder="Текст" class="setting-prop">' +
								'<input type="text" name="newSettings[SLIDER][' + newCnt + '][link]"  placeholder="Ссылка" class="setting-prop">' +
								'<input type="text" name="newSettings[SLIDER][' + newCnt + '][btn_text]"  placeholder="Текст кнопки" class="setting-prop">' +
							'</div>' +
						'</div>';
			btn.before( html );

			$('#' + newCnt + '_slideImg').change(function(e){
				app.$root.showImagePreview(e)
			})

		},
		saveSetings: function(e){
			var btn = $(e.target);
			var self = this;
			var form = $("#settings_form");
			self.loading = true;

			btn.addClass("btn-loading");

			var formData = new FormData();

			// Сбор данных из обычных полей
			form.find('input[name]').not('[type="file"]').each(function() {
				var field = $(this);
				if($(this).attr("type") == "checkbox"){
					if($(this).prop("checked")) formData.append(field.attr('name'), field.val());
				}else formData.append(field.attr('name'), field.val());
			});

			var selectFields = form.find('select');
			selectFields.each(function() {
				var field = $(this);
				var arr = field.val();
				for (var i = 0; i < arr.length; i++) {
					formData.append(field.attr('name'), arr[i]);
				}
			});

			var filesFields = form.find('input[type="file"]');
			filesFields.each(function() {
				var field = $(this);
				formData.append(field.attr('name'), field.prop('files')[0]);
			});
			$.ajax({
                url: self.$parent.ajaxUrl + "settings.php?action=saveSettings",
                type: 'POST',
                data: formData,
                contentType: false,
                cache: false,
                processData: false,
                success: function(json) {
					var json = JSON.parse(json);
					self.loading = false;

					if(json.status){
						self.$parent.isShowSettings = false;
						self.arSettings = json.arSettings;
						self.$parent.arSettings = json.arSettings;
						btn.removeClass("btn-loading");

						self.$root.$emit('settingsUpdated', json.arSettings);
						//window.location.reload();
					}else{
						///console.error(json.error);
					}
                }
            });


			/*
			var formData = $(".settings-form").serialize();

			$.post(self.$parent.ajaxUrl + "settings.php?action=saveSettings", formData, function(json){
				var json = JSON.parse(json);
				self.loading = false;

				if(json.status){
					self.$parent.isShowSettings = false;
					self.arSettings = json.arSettings;
					self.$parent.arSettings = json.arSettings;
					btn.removeClass("btn-loading");

					self.$root.$emit('settingsUpdated', json.arSettings);
					//window.location.reload();
				}else{
					console.error(json.error);
				}
			})
			*/
		},
		toggleSetingTab: function(item){
			var self = this;
			var arSettings = self.$parent.arSettings;
			for(var i in arSettings) arSettings[i].opened = false;
			item.opened = true;
		},
		toggleSetingItem: function(item){
			var self = this;
			var arSettings = self.$parent.arSettings;
			for(var i in arSettings) {
				for(var x in arSettings[i].items) {
					if(item.CODE != arSettings[i].items[x].CODE) arSettings[i].items[x].SHOW = false;
				}
			}
			item.SHOW = !item.SHOW;
		},
	},
	updated: function(){
		var self = this;
		//
		if($(".js-settings-select.select2-mode").length){
			$(".js-settings-select.select2-mode").select2({
				closeOnSelect : false,
				allowHtml: true,
				allowClear: true,
				width:"100%",
				tags: true,
				placeholder: "",
			});
		}
		if($(".js-settings-select.sumoselect-mode").length){
			$('.js-settings-select.sumoselect-mode').SumoSelect({
				search: true,
				searchText: 'Поиск...',
				captionFormat: '{0} Выбрано',
				captionFormatAllSelected: '{0} все выбраны!',
				locale :  ['OK', 'Отмена', 'Выбрать все'],
				selectAll : true,
				okCancelInMulti: true,
			});
		}

		$( ".settings-blocks-conainer.sortable-mode" ).sortable({
			placeholder: "ui-state-highlight"
		});
	},
	watch: {
		isShowSettings(to, from) {
			var self = this;
			if(self.$root.isShowSettings){
				setTimeout(function(){
					self.loading = false;
				}, 800)
				$('body').addClass('no-scroll');
			}else $('body').removeClass('no-scroll');
		},
		searchOpened(searchOpened) {
			var self = this;
			if(searchOpened) {
				$('body').addClass('no-scroll');
				setTimeout(function(){
					self.searchVisible = true;
				}, 50)
			}else {
				$('body').removeClass('no-scroll');
				$("#quick-search-result .product-grid__item").removeClass(self.showClass);
			}
		},
		pagePath: function (newVal, oldVal) {

			//this.closeSearch();
		},
		route_params: function (newVal, oldVal) {
			var self = this;
			self.loadingSearch = true;
			if(oldVal.query.page || newVal.query.page){
				if(newVal.query.page) self.navPageNum = newVal.query.page.replace("/", "");
				else self.navPageNum = 1;
				self.loadSearchResult();
			}

			if(newVal.path != oldVal.path) this.closeSearch();
		},
		showMore: function(showMore){
			var self = this;

		},
	},
};
</script>

<style>
.settings-block-showed{	margin-right: 10px;}
.empty-result-container{position:relative;}
.empty-result-text b{font-family: 'Museo Sans Cyrl 700', sans-serif;}
.absolute-menu{
    position: relative;
    height: 100%;
}
.component-loading-text{
	content: "";
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background-color: transparent;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 30;
    font-size: 12px;
    line-height: 17px;
    font-family: 'Museo Sans Cyrl 300', sans-serif;
    color: #52504C;
    margin-bottom: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.component-loading-progress{
	content: "";
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background-color: transparent;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 30;
    font-size: 12px;
    line-height: 17px;
    font-family: 'Museo Sans Cyrl 300', sans-serif;
    color: #52504C;
    margin-bottom: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.component-loading-progress .bar-progress{
    display: flex;
    width: 300px;
    height: 4px;
    background: #eee;
    margin-top: 10px;
    position: relative;
}
.component-loading-progress .bar-progress .bar-progress-ready{
	position: absolute;
	display: block;
    position: absolute;
    left: 0;
    height: 100%;
    background: #3464c9;
    width: 0%;
}
.show .component-loading-text,
.component-loading-text.show {
    opacity: 1;
    visibility: visible;
}
.show .component-loading-progress,
.component-loading-progress.show {
    opacity: 1;
    visibility: visible;
}
#quick-search-result .hidden{
	display:none;
}
#quick-search-result .pages-info{
	color: #fff;
}
#quick-search-result .js-catalog-load-more{
	background-color: #fff;
}
#quick-search-result .js-catalog-load-more:hover{
	background-color: #4672d0;
}
#quick-search-result{
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    flex-direction: column;
    align-items: center;
    background: rgb(0 0 0 / 54%);
    z-index: 1199;
    padding-top: 78px;
    padding-bottom: 30px;
	transition: all 0.3s ease 0.2s;
    height: 100%;
    overflow: auto;
	opacity:0;
	scroll-behavior: smooth;
	backdrop-filter: blur(5px);
}
#quick-search-result .product-list .component-loading{
    background-color: rgb(255 255 255 / 56%);
    height: calc(100vh);
    top: 0;
    position: fixed;
}
#quick-search-result .product-list{
	position: relative;
	margin-top: 68px;
}
#quick-search-result.show{
    display: flex;
}
#quick-search-result.show.visible{
	opacity:1;
	transition: all 0.3s ease;
}
#quick-search-result .product-list,
#quick-search-result .animated-page,
#quick-search-result .catalog__grid{width:100%;}

#quick-search-result .product-grid{
	width: 800px;
    max-width: 90%;
	margin: auto;
}
#quick-search-result .search-header{
	transition: all 0.3s;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    display: flex;
    position: fixed;
    min-height: 60px;
    width: 800px;
    max-width: 90%;
    margin-top: 3px;
    margin-bottom: 3px;
    z-index: 1;
	box-shadow: 0 0 10px #000;

	top: 0px;
	opacity:0;
}
#quick-search-result.show.visible .search-header{
	top:78px;
	opacity:1;
}
#quick-search-result .search-header .modal__close{
    top: 8px;
    right: 0;
}
#quick-search-result .search-header .component-loading{
	right: 0;
    left: unset;
    width: 38px;
    height: 50px;
    min-height: 60px;
    background-size: 35px 35px;
    z-index: 100;
    background-color: #fff;
    transition: all 0.1s;
}
#quick-search-result .search-header .component-loading.show{
	opacity: 1;
}
#quick-search-result .search-header .search-header-text{
	line-height: 60px;
    padding-left: 30px;
    font-size: 18px;
}
#quick-search-result .search-header .search-header-text span{
	font-family: 'Museo Sans Cyrl 700', sans-serif;
	color: #3363c9;
}
#quick-search-result .search-header .search-header-count{
	position: absolute;
    display: block;
    right: 50px;
    top: 50%;
    margin-top: -10px;
    font-size: 14px;
	opacity: 0;
    transition: all 0.3s;
}
#quick-search-result .search-header .search-header-count.show{
	opacity: 1;
}
#quick-search-result .search-header .search-header-count span{
	font-family: 'Museo Sans Cyrl 700', sans-serif;
	color: #3363c9;
}


#quick-search-result .product-list .product-grid__item.animated{
	opacity: 0;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}

.btn{white-space: nowrap;}
.btn.disabled,
a.disabled{
    pointer-events: none;
    background: #eaeaea;
    color: #000000;
    opacity: 0.4;
}
.breadcrumbs li:nth-last-child(1) a:after {
    display: none;
}
#main{min-height:auto;}
#full_content{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    max-width: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
}

.settings-blocks-conainer.sortable-mode{
	list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-width: 400px;
}
.settings-blocks-conainer.sortable-mode .ui-sortable-handle{
	cursor: pointer;
    transition: all 0.3s;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 20px;
    background: #fff;
	align-items: center;
}
.settings-blocks-conainer.sortable-mode .ui-sortable-handle.ui-sortable-helper{
	box-shadow: rgb(0 0 0 / 45%) 0px 0px 20px;
}
.settings-blocks-conainer.sortable-mode .ui-state-highlight{
    margin-bottom: 5px;
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 20px;
	height: 47px;
}
.settings-blocks-conainer.sortable-mode .settings-block-icon{
    transition: all 0.3s;
}
.settings-blocks-conainer.sortable-mode .settings-block-name{
    transition: all 0.3s;
    margin-left: 30px;
	font-size: 14px;
    letter-spacing: -0.408px;
	width: 190px;
}
.settings-blocks-conainer.sortable-mode .settings-block-link{
	font-size: 14px;
    letter-spacing: -0.408px;
	font-weight: 600;
}
.ui-sortable-handle:hover .settings-block-icon{
	color: #3363C9;
}
.ui-sortable-handle:hover .settings-block-name{
	color: #3363C9;
}
.settings-btn{
    display: block;
    position: fixed;
    width: 50px;
    height: 50px;
    top: 100px;
    right: 0;
    background: #4672d0;
    box-shadow: 0 0 20px rgb(0 0 0 / 40%);
    border-radius: 12px 0 0 12px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s;
}
.settings-btn img{
    transition: all 0.3s ease;
}
.settings-btn:hover{
    background: #3363c9;
}
.settings-btn:hover img{
	transform: rotate(360deg);
}
#settings-modal{
    transition: background 0.3s;
	display: block;
    position: fixed;
    width: 0;
	top: 0;
    right: 0;
    background: rgb(0 0 0 / 0%);
}
#settings-modal.show{
	display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: rgb(0 0 0 / 43%);
    z-index: 2000;
}
#settings-modal .settings-content{
	background: #fff;
    width: 1000px;
    height:100%;
    padding: 35px;
    transition: all 0.3s ease;
    position: fixed;
    top: 0;
	right:-1000px;
	overflow-y: auto;
	padding-bottom: 65px;
}
#settings-modal .setting-block{
	margin-bottom: 10px;
}
#settings-modal .setting-block .block-content{
	height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease;
}
#settings-modal .SumoSelect {
    width: 100%;
}
#settings-modal .SumoSelect .select-all {
    height: 40px;
}
#settings-modal .setting-block .block-content.show{
	opacity: 1;
	height: auto;
}
#settings-modal .setting-block.sortable-mode .block-content.show,
#settings-modal .setting-block.sumoselect-mode .block-content.show{
    overflow: visible;
}
#settings-modal .block-title{
	padding-bottom: 15px;
	font-family: 'Museo Sans Cyrl 700', sans-serif;
    transition: all 0.3s ease;
	cursor: pointer;
}
#settings-modal .block-title span{
	border-bottom: 1px dashed;
}
#settings-modal .block-title:hover{
	color: #3363c9;
}
#settings-modal.show .settings-content{
	right:0;
}
#settings-modal.show .settings-btn{
	right:1000px;
}
#settings-modal .js-settings-select{
	width: 100%;
	max-width: 100%;
    min-height: 300px;
    outline: none;
    padding: 5px;
    border: 1px solid #e0e0e0;
	border-radius: 5px;
}
#settings-modal .select2-container{
    z-index: 2001;
}
#settings-modal .select2-search--inline{
	display: inline-block;
}
#settings-modal .settings-footer{
	display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: left;
    padding: 10px 35px 20px 35px;
}
#settings-modal .SumoSelect .select-all.partial>span i,
#settings-modal .SumoSelect .select-all.selected>span i,
#settings-modal .SumoSelect>.optWrapper.multiple>.options li.opt.selected span i{
	background-color: #4672d0;
}
select.sumoselect-mode{visibility: hidden;}

.settings-content{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}
.settings-tabs-content{
    display: flex;
    width: 100%;
    height: 100%;
	background: #f6f8fb;
	border: 1px solid #E5E5E5;
	box-shadow: inset 0 0 10px rgb(0 0 0 / 14%);
	overflow: auto;
}

.settings-tabs{display: flex;}
.settings-tabs .settings-tab{
	display: flex; padding: 10px 20px; color: #3363c9;
	transition: all 0.3s ease; cursor:pointer;
	font-family: 'Museo Sans Cyrl 700', sans-serif;
}
.settings-tabs .settings-tab span{border-bottom: 1px dashed; line-height: 20px;}
.settings-tabs .settings-tab.is-active{background: #3363c9;color: #fff;}
.settings-tabs .settings-tab.is-active span{border-bottom: 1px dashed transparent;}
.settings-tabs .settings-tab:not(.is-active):hover{background: #f6f8fb;}

.settings-tab-content{display:none; padding:20px;}
.settings-tab-content.is-open{display:block;}

@media print {
	.noPrint{
		display:none !important;
	}
	#content{
	    max-width: 100%;
		margin-left: 0;
	}
	body {
		padding-top: 0;
	}
}
</style>
