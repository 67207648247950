<template>
	<section class="b-content b-content_order" id="printableArea">

		<div v-bind:class="{ show: loading }" class="component-loading"></div>

		<div class="b-content__header">
			<h2 class="b-content__title">Заказ {{result.ACCOUNT_NUMBER}} от {{result.DATE_INSERT_FORMATED}} <span v-if="result.CANCELED == 'Y'" class="order-canceled">(Отменен)</span></h2>
			<div class="b-content__header-aside">
				<div class="order-entity">
					<div class="order-entity__user">Оформил  пользователь: <span v-html="result.ORDER_PROPS.CONTACT_PERSON.VALUE"></span></div>
				</div>
			</div>
		</div>
		<div class="b-content__body">
			<div class="order-detail">
				<div v-if="loaded" class="resp-table-wrap">
					<table class="t-orders">
						<thead>
							<tr>
								<th>№ заказа</th>
								<th>Дата заказа</th>
								<th>Сумма заказа</th>
								<th width="130">Статус заказа</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td v-html="result.ACCOUNT_NUMBER"></td>
								<td v-html="result.DATE_INSERT_FORMATED"></td>
								<td v-html="result.PRICE_FORMATED"></td>
								<td>
                  <div class="status-selector">
                    <div class="current-status" onclick="$(this).parent().toggleClass('opened')">
                      <span class="status" v-bind:style="'color:' + result.STATUS.COLOR" v-html="result.STATUS.NAME"></span>
                      <svg class="svg-icon" width="8" height="5">
                        <use xlink:href="/images/sprite.svg#arrow_down"></use>
                      </svg>
                    </div>
                    <div class="statuses-list">
                      <div v-for="item in result.STATUSES" v-bind:class="{ 'selected': item.SELECTED }" class="statuses-list-option" @click="setStatus(item.ID)">
                        <span class="radio-icon"></span>
                        <span v-bind:data-color="item.COLOR">{{item.NAME}}</span>
                      </div>
                    </div>
                  </div>
                </td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="order-detail-params">
					<h2 class="b-content__title">Параметры заказа</h2>
					<div class="modal_row">
						<div class="modal_col-6">
							<h4 class="b-content__subtitle">Контактное лицо:</h4>
							<div class="control_text">
								<input placeholder="Фамилия, имя" type="text" name="FIELDS[NAME]" readonly v-bind:value="result.ORDER_PROPS.CONTACT_PERSON.NAME">
							</div>
							<div class="control_text">
								<input placeholder="E-mail" type="text" name="FIELDS[EMAIL]" readonly v-bind:value="result.ORDER_PROPS.CONTACT_PERSON.EMAIL">
							</div>
							<div class="control_text">
								<input placeholder="Контактный телефон" type="text" readonly name="FIELDS[PHONE]" v-bind:value="result.ORDER_PROPS.CONTACT_PERSON.PHONE">
							</div>
						</div>
						<div class="modal_col-6">
							<h4 class="b-content__subtitle">Комментарий к заказу</h4>
							<div class="control_text">
								<textarea name="FIELDS[COMMENT]" v-bind:value="result.COMMENT"></textarea>
							</div>
						</div>
					</div>
				</div>

        <div class="order-section">
          <h2 class="order-section__title">Перечень товаров</h2>
          <div
            v-if="loaded"
            :class="[
              'cutter js-cutter',
              { 'small-basket' : result.BASKET.length < 8 },
              { 'is-open': showAllOrder || result.BASKET.length < 10 }
            ]"
          >
            <div
              ref="cart-items"
              class="cutter__target"
              :style="getMaxHeight"
            >
              <div class="resp-table-wrap">
                <table class="product-table">
                  <thead ref="cart-header">
                    <tr>
                    <th>№</th>
                    <th>Фото</th>
                    <th>Арт.</th>
                    <th>Наименование</th>
                    <th>Кол-во</th>
                    <th>Цена за ед.</th>
                    <th>Стоимость</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in result.BASKET"
                      ref="cart-item"
                    >
                      <td>{{item.NUM}}</td>
                      <td><div class="item-img" style="width:40px;"><img width="40" class="t-prod-pic" v-bind:src="item.PREVIEW_PICTURE" alt="" /></div></td>
                      <td><router-link class="t-prod-art" :to="item.DETAIL_PAGE_URL" v-html="item.ARTICLE"></router-link></td>
                      <td v-html="item.NAME"></td>
                      <td><b>{{item.QUANTITY}}{{item.MEASURE_TEXT}}</b></td>
                      <td style="white-space:nowrap;"><b v-html="item.PRICE_FORMATED"></b></td>
                      <td style="white-space:nowrap;"><b v-html="item.FORMATED_SUM"></b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <button
              v-show="result.BASKET.length > 10"
              :class="[
                'cutter__trigger js-cutter-trigger noPrint',
                { 'is-open' : showAllOrder }
              ]"
              data-open="Показать весь список"
              data-close="Свернуть"
              @click="showAllOrder = !showAllOrder"
            >
              <span v-if="showAllOrder">Скрыть</span>
              <span v-else>Показать весь список</span>
            </button>
            <!--a href="javascript:;" onclick="app.printDiv('printableArea')" class="btn btn_light btn_download noPrint"><span class="btn_text">Бланк заказа</span>
              <svg class="svg-icon" width="13" height="13">
                <use xlink:href="/images/sprite.svg#download"></use>
              </svg>
            </a-->
          </div>
        </div>


				<div class="order-detail__footer noPrint">
          <div class="send_form">
            <input type="email" required name="EMAIL" class="email_input" placeholder="Введите e-mail ..."/>
            <a class="send_btn" @click="sendEmailOrder">
              <span class="btn_text">Отправить заказ</span>
              <svg class="svg-icon" width="16" height="14">
                <use xlink:href="/images/sprite.svg#send"></use>
              </svg>
            </a>

            <div v-if="send_error" class="order-send_result error">
              <span>Упс, не получилось доставить.<br>Проверьте корректность введенных данных</span>
            </div>
            <div v-else-if="send_success" class="order-send_result success">
              <span>Заказ №{{ order_id }} успешно отправлен на ваш e-mail<br>{{email}}</span>
            </div>

          </div>
          <a v-bind:href="'/data/ajax/client_order.php?action=print&ID=' + order_id" target="_blank" class="btn btn_light btn_download">
            <svg class="svg-icon" width="22" height="20">
              <use xlink:href="/images/sprite.svg#excel"></use>
            </svg>
            <span class="btn_text">Скачать заказ в Exсel</span>
          </a>
        </div>
				<div class="order-detail__footer noPrint">

					<router-link class="btn btn_back" :to="'/orders_client/'">
						<svg class="svg-icon" width="7" height="13">
							<use xlink:href="/images/sprite.svg#caret-left"></use>
						</svg>
						<span class="btn_text">Назад</span>
					</router-link>
					<!--button class="btn btn_lblue">
						<svg class="svg-icon" width="20" height="15">
							<use xlink:href="/images/sprite.svg#envelope"></use>
						</svg>
						<span class="btn_text">Написать менеджеру</span>
					</button-->
					<button class="btn btn_lblue" @click="editOrder">
						<span class="btn_text">Редактировть заказ</span>
					</button>
					<button class="btn btn_lblue" @click="copyOrder">
						<span class="btn_text">Повторить заказ</span>
					</button>
				</div>
			</div>
		</div>

    <div v-bind:class="{ 'is-open': showEditOrder }"  class="modal modal_edit-order " id="modal-edit-order">
      <div class="modal__wrap">
        <div class="modal__inner animated zoomIn">
          <button class="modal__close" v-on:click="to_hide = true"></button>
          <div :class="{ loading: loadingSearch }" class="modal_edit-header">
            <div class="modal_edit-search">
              <input type="text" name="item_art" id="modal_edit_input"
                     placeholder="Добавьте товар в заказ по артикулу..."
                     v-on:input="searchProiduct()" v-on:focus="searchProiduct()"/>
              <svg width="17" height="16"><use xlink:href="/images/sprite.svg#search"></use></svg>
            </div>
            <div class="modal_edit-search_result">
              <div :class="{ show: loadingSearch }" class="component-loading"></div>
              <div :data-page-size="navPageSize" v-bind:class="[viewMode, { 'loading': loadingSearch }]" class="product-grid" v-bind:data-page="navPageNum">
                <component-list-item
                    v-for="productItem in searchItems"
                    v-bind:can_delete="can_delete"
                    v-bind:data-page="navPageNum"
                    v-bind:key="'list_component_'+productItem.id"
                    v-bind="productItem"
                ></component-list-item>
              </div>

            </div>
          </div>
          <div class="modal_edit-container">
            <form class="modal_edit-form">
              <div class="modal_edit-order_data">
                <div class="modal_col-4">
                  <h4>Заказ № {{order_id}}</h4>
                  <div class="control_text">
                    <input placeholder="Фамилия, имя" type="text" name="FIELDS[NAME]" v-bind:value="result.ORDER_PROPS.CONTACT_PERSON.NAME">
                  </div>
                  <div class="control_text">
                    <input placeholder="E-mail" type="text" name="FIELDS[EMAIL]" v-bind:value="result.ORDER_PROPS.CONTACT_PERSON.EMAIL">
                  </div>
                  <div class="control_text">
                    <input placeholder="Контактный телефон" type="text" name="FIELDS[PHONE]" v-bind:value="result.ORDER_PROPS.CONTACT_PERSON.PHONE">
                  </div>
                </div>
                <div class="modal_col-8">
                  <label class="b-content__subtitle">Комментарий к заказу</label>
                  <div class="control_text">
                    <textarea name="FIELDS[COMMENT]" v-bind:value="result.COMMENT"></textarea>
                  </div>
                </div>
              </div>
            </form>

            <div class="b-content_cart">
              <div class="resp-table-wrap">
                <table class="product-table">
                  <thead>
                    <tr>
                      <th width="28">&nbsp;</th>
                      <th width="40">№</th>
                      <th width="40">Фото</th>
                      <th width="80"><button class="sort" data-field="art">Арт.</button></th>
                      <th class="left">Наименование</th>
                      <th width="100">Количество</th>
                      <th width="120">Стоимость</th>
                      <th width="30">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody v-if="loaded">
                   <tr v-for="productItem in items" class="">
                     <td colspan="5">
                       <div class="t-prod-meta">
                         <span>Товар</span>
                         <b v-html="productItem.name"></b>
                         <span v-if="productItem.arhive">снят с производства</span>
                         <span v-else-if="!productItem.can_buy">{{productItem.stock_date}}</span>
                         <span v-else-if="productItem.deleted">товар был удален</span>
                       </div>
                     </td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td>{{ productItem.num }}</td>
                     <td>
                       <img class="t-prod-pic" v-bind:src="productItem.image" alt="" />
                     </td>
                     <td>
                       <router-link v-if="productItem.link" class="t-prod-art" v-bind:to="productItem.link">{{ productItem.article }}</router-link>
                     </td>
                     <td v-if="productItem.can_buy"><div class="t-prod-name" v-html="productItem.name"></div></td>
                     <td v-if="productItem.can_buy">
                       <div class="counter js-counter">
                         <button @click="counterMinus(productItem)" type="button" class="btn decrease">-</button>
                         <input class="count" type="text" name="quantity"
                                :data-id="productItem.id"
                                :data-ratio="productItem.ratio"
                                :data-price="productItem.ratio_price.discount"
                                :value="productItem.arBasketElement.QUANTITY" autocomplete="off" v-on:input="counterInput(productItem)"/>
                         <button @click="counterPlus(productItem)" type="button" class="btn increase">+</button>
                       </div>
                     </td>
                     <td v-if="productItem.can_buy && !productItem.deleted"><b class="product-total-price" v-html="productItem.arBasketElement.SUMM_FORMATTED"></b></td>
                     <td>
						<!--
                       <button v-if="productItem.can_buy && !productItem.deleted" v-on:click.prevent="$root.userItemsListsAction('basket', productItem.id, 0)" class="t-prod-remove js-cart-remove"></button>
                       <button v-else v-on:click.prevent="deleteItem" class="t-prod-remove js-cart-remove"></button>
					   -->
					   <button @click="deleteProduct(productItem.id)" class="t-prod-remove js-cart-remove"></button>
                     </td>
                   </tr>

                  </tbody>
                </table>
              </div>
            </div>

          </div>

          <div class="modal_edit-footer">
            <div class="total-amount" v-html="title_text"></div>
            <a href="javascript:;" @click="saveOrder" class="btn">Сохранить изменения</a>
          </div>

        </div>
      </div>
    </div>
	</section>
</template>

<script>
import searchItemClientComponent from '../components/searchItemClientComponent';
import 'jquery-ui/ui/widgets/datepicker';
export default {
	data () {
		var self = this;
		return {
			name: "order_detail",
			order_id: parseInt(self.$route.params.order_id),
			loading: true,
			loadingSearch: false,
			loaded: false,
			to_hide: false,
      email: "",
      send_error: false,
      send_success: false,
      showEditOrder: false,
			showAllOrder: false,
      can_delete: false,
			show: false,
			arHolidays: [],
			result: {
        BASKET: [],
				ORDER_PROPS: {
					COMPANY: {},
					CONTACT_PERSON: {},
				},
				ACCOUNT_NUMBER: "",
			},
			breadcrumbs: [],
      items: {},
      searchItems: [],
      navPageSize: 50,
      navPageCount: 1,
      navPageNum: 1,
      navParam: {},
      filter: {},
      timer: false,
      viewMode: "list-mode",
      sort: {
        by: "sort",
        order: "asc"
      },
      containerMaxHeight: 0
		}
	},
  components: {
    'component-list-item': searchItemClientComponent,
  },
	computed: {
    basket_count() {
      var self = this;
      return this.result.BASKET.length;
    },
    basket_sum() {
      var self = this;
      return this.result.PRICE_FORMATED;
    },
    getMaxHeight() {
      return {
        maxHeight: this.showAllOrder ? '100%' : this.containerMaxHeight
      }
    },
    title_text() {
      var self = this;
      return self.basket_count + " " + self.$root.declOfNum(self.basket_count, ['позиция', 'позиции', 'позиций']) + " на сумму <b>" + self.basket_sum + "</b>";
    },
    title_total_text() {
      var self = this;
      return "Всего " + self.basket_total_count + " " + self.$root.declOfNum(self.basket_total_count, ['товар', 'товара', 'тоааров']) + "</b>";
    },
	},
	watch: {
    showEditOrder: function(){
      var self = this;
      console.log(self.showEditOrder);
      if(self.showEditOrder) {
        $("#modal-overlay").fadeIn();
      }else {
        $("#modal-overlay").fadeOut();
      }
    },
		to_hide:function(to_hide){
			var self = this;
			if(to_hide){
				$("#modal-edit-order .modal__inner").addClass("zoomOut");
				setTimeout(function(){
					self.showEditOrder = false;
					self.to_hide = false;
					$("#modal-edit-order .modal__inner").removeClass("zoomOut");
				}, 300);
			}
		}
	},
	mounted (){
		var self = this;
		self.loadOrder();
    setTimeout(() => {
      this.calculateContainerHeight()
    }, 2000)
	},
  methods: {
    addToOrder: function(productItem){
      var self = this;
      productItem.num = self.items.length + 1;
      self.result.basket_items.push(productItem.element_id);

      self.items.push(productItem);
      self.searchItems = [];

      console.log(productItem);
    },
    calcPrices: function(e) {
      var self = this,
          totalPrice = 0;

      for(var i in self.items) {
        var productItem = self.items[i];
        totalPrice = totalPrice + productItem.arBasketElement.SUMM;
      }

      //$(".total-amount").html(totalPrice);
      self.result.PRICE = totalPrice;
      self.result.PRICE_FORMATED = self.$root.number_format(totalPrice, 2, ".", " ") + ' <span class="rub">₽</span>';;
    },
    searchProiduct: function (){
      var self = this,
          inputVal = event.target.value;
      if(inputVal != "") self.loadingSearch = true;

      if(!!self.timer) clearTimeout(this.timer);

      self.timer = setTimeout(function() {
        var filter = {"%PROPERTY_CML2_ARTICLE": inputVal, "!ID": self.result.basket_items, IBLOCK_ID: 56};
        var urlData = {
          filter: filter,
          size: 5,
          SORT: self.sort.by,
          ORDER: self.sort.order,
        };
        if(inputVal == ""){
          self.searchItems = [];
          self.loadingSearch = false;
        }else{
          $.getJSON("/data/components/sectionFiltered.php", urlData, function(data) {
            for(var i in data.items) data.items[i].element_id = data.items[i].id;
            self.searchItems = data.items;
            self.loadingSearch = false;

            //console.log(data);
          })
        }
      }, 1000);
    },
    counterInput: function(productItem){
      var self = this,
          inputVal = event.target.value;

      if(!!self.timer) clearTimeout(this.timer);

      self.timer = setTimeout(function(){
        self.curCount = inputVal;

        self.$nextTick(function () {
          var ratio = parseFloat(productItem.ratio);
          var newValue = Math.ceil(inputVal/ratio) * ratio;
          if(newValue > parseFloat(productItem.product.QUANTITY)) newValue = parseFloat(productItem.product.QUANTITY);
          if(newValue <= 0) newValue = parseFloat(productItem.ratio);
          productItem.arBasketElement.QUANTITY = newValue;
          productItem.arBasketElement.SUMM = parseFloat(newValue / parseFloat(productItem.ratio) * productItem.ratio_price.discount);
          productItem.arBasketElement.SUMM_FORMATTED = self.$root.number_format(productItem.arBasketElement.SUMM, 2, ".", " ") + ' <span class="rub">₽</span>';

          self.calcPrices();
        })

      }, 1000);
    },
    counterPlus: function(productItem){
      var self = this;
      var newValue = parseFloat(productItem.arBasketElement.QUANTITY) + parseFloat(productItem.ratio);
      if(newValue > parseFloat(productItem.product.QUANTITY)) {
        newValue = parseFloat(productItem.product.QUANTITY);
      }
      productItem.arBasketElement.QUANTITY = newValue;
      productItem.arBasketElement.SUMM = parseFloat(newValue / parseFloat(productItem.ratio) * productItem.ratio_price.discount);
      productItem.arBasketElement.SUMM_FORMATTED = self.$root.number_format(productItem.arBasketElement.SUMM, 2, ".", " ") + ' <span class="rub">₽</span>';

      self.calcPrices();
    },
    counterMinus: function(productItem){
      var self = this;
      var newValue = parseFloat(productItem.arBasketElement.QUANTITY) - parseFloat(productItem.ratio);
      if(newValue <= 0) newValue = parseFloat(productItem.ratio);
      productItem.arBasketElement.QUANTITY = newValue;
      productItem.arBasketElement.SUMM = parseFloat(newValue / parseFloat(productItem.ratio) * productItem.ratio_price.discount);
      productItem.arBasketElement.SUMM_FORMATTED = self.$root.number_format(productItem.arBasketElement.SUMM, 2, ".", " ") + ' <span class="rub">₽</span>';

      self.calcPrices();
    },
    saveOrder: function(){
      var self = this;
      self.to_hide = true;

      var name = $( '#modal-edit-order [name="FIELDS[NAME]"]' ).val();
      var email = $( '#modal-edit-order [name="FIELDS[EMAIL]"]' ).val();
      var phone = $( '#modal-edit-order [name="FIELDS[PHONE]"]' ).val();
      var comment = $( '#modal-edit-order [name="FIELDS[COMMENT]"]' ).val();

      self.result.ORDER_PROPS.CONTACT_PERSON.NAME = name;
      self.result.ORDER_PROPS.CONTACT_PERSON.EMAIL = email;
      self.result.ORDER_PROPS.CONTACT_PERSON.PHONE = phone;
      self.result.COMMENT = comment;



      var it = [];
      var basketItems = [];
      $.each(
          self.items,
          function ( index, item )
          {
            var i = new Object;
            i.ID = parseInt( item.id );
            if ( !i.ID )
            {
              i.ID = parseInt( item.element_id );
            }

            i.QTY = parseInt( item.arBasketElement.QUANTITY );
            i.PRICE = parseFloat( item.price.value );
            i.SUMM = parseFloat( item.arBasketElement.SUMM );

            it.push( i );


            var b = new Object;
            b.ID = i.ID;
            b.NUM = index + 1;
            b.PREVIEW_PICTURE = item.image;
            b.DETAIL_PAGE_URL = item.link;
            b.ARTICLE = item.article;
            b.NAME = item.name;
            b.QUANTITY = item.arBasketElement.QUANTITY;
            b.QTY = item.arBasketElement.QUANTITY;
            b.MEASURE_TEXT = '';
            b.PRICE = item.price.value;
            b.PRICE_FORMATED = item.price.print;
            b.SUMM = item.arBasketElement.SUMM;
            b.FORMATED_SUM = item.arBasketElement.SUMM + ' ₽';
            b.SUMM_FORMATTED = item.arBasketElement.SUMM + ' ₽';

            basketItems.push( b );
          }
      );

      self.result.BASKET = basketItems;


      $.post(
          '/data/ajax/save_order.php',
          {
            orderId: self.order_id,
            name: name,
            email: email,
            phone: phone,
            comment: comment,
            items: it,
          }
      );


    },
    deleteProduct: function( productId ){
      var self = this;


      $.each(
          self.result.BASKET,
          function ( index, basketItem )
          {
            if ( basketItem.ID == productId )
            {
              self.result.BASKET.splice( index, 1 );
              self.items.splice( index, 1 );
            }
          }
      );

      var items = [];
      $.each(
          self.items,
          function ( index, item )
          {
            var i = new Object;
            i.ID = parseInt( item.id );
            if ( !i.ID )
            {
              i.ID = parseInt( item.element_id );
            }

            i.QTY = parseInt( item.arBasketElement.QUANTITY );
            i.PRICE = parseFloat( item.price.value );
            i.SUMM = parseFloat( item.arBasketElement.SUMM );
          }
      );

      $.post(
          '/data/ajax/order_items.php',
          {
            orderId: self.order_id,
            items: items,
          }
      );


    },
    editOrder: function(){
      var self = this;
      self.showEditOrder = true;
      self.filter = {"ID": self.result.basket_items, IBLOCK_ID: 56};
      var self = this,
          baseUrl = (self.$route.meta.baseUrl ? self.$route.meta.baseUrl : self.$route.path),
          urlData = {
            filter: self.filter,
            size: self.navPageSize,
            baseUrl: baseUrl,
            SORT: self.sort.by,
            ORDER: self.sort.order,
          };
      urlData["PAGEN_1"] = self.navPageNum;
      self.items = {};

      $.getJSON("/data/components/sectionFiltered.php", urlData, function(data){
        for(var i in data.items) {
          data.items[i].num = parseInt(i) + 1;
          for(var x in self.result.BASKET) if(self.result.BASKET[x].ID == data.items[i].id) {
            data.items[i].arBasketElement = self.result.BASKET[x];
            data.items[i].arBasketElement.SUMM_FORMATTED = self.$root.number_format(parseFloat(data.items[i].arBasketElement.SUMM), 2, ".", " ") + ' <span class="rub">₽</span>';
          }
        }
        self.items = data.items

        //console.log(self.items);

        self.emptyResult = data.items.length <= 0;
        self.loading = false;
        self.navParam = data.nav;
        self.navPageCount = data.nav.NavPageCount;

      })


    },
    sendEmailOrder: function(){
      var self = this;
      self.email = $(".email_input").val();
      if(!app.checkEmail(self.email)){
        self.send_error = true;
      }else{
        self.loading = true;
        self.send_error = false;
        $.getJSON("/data/ajax/client_order.php?action=send&email=" + self.email + "&ID=" + self.order_id, function(data){
          self.send_success = true;
          self.loading = false;
        });
      }

    },
    setStatus: function (statusID){
      var self = this;
      self.loading = true;
      var data = {
        set_status: "Y",
        status_id: statusID,
        order_id: self.order_id
      };
      $.getJSON("/data/components/order_detail_client.php", data, function(json) {
        $('.status-selector').removeClass('opened');
        self.loadOrder();
      })
    },
    copyOrder: function(){
      var self = this;
      self.loading = true;
      var data = {
        copy_order: "Y",
        ID: self.order_id
      };
      $.getJSON("/data/components/order_detail_client.php", data, function(json){
        if(json.status) {
          self.$root.arUser.basket = json.basket;
          self.$nextTick(function(){
            self.loaded = true;
            self.$router.push({
              path: '/cart/'
            });
          });
        }else{
          alert(json.error);
        }
        self.loading = false;
      });
    },
    loadOrder: function(){
      var self = this;

      var urlData = {order_id: self.order_id};

      $.getJSON("/data/components/order_detail_client.php", urlData, function(json){
        if(json.status) {
          self.result = json.result;

          self.$nextTick(function(){
            var ORDER_ID = parseInt(json.result.ID);

            self.loaded = true;
            self.breadcrumbs = [
              { link: '/', name: 'Главная' },
              { link: '/orders_client/', name: 'Мои заказы' },
              { link: '/orders_client/order/' + self.$route.params.order_id + "/", name: 'Заказ #' + self.result.ACCOUNT_NUMBER },
            ];

            self.$root.breadcrumbs = self.breadcrumbs;

          });
        }
        self.loading = false;
      });
    },
    in_array: function(value, array) {
      for(var i=0; i<array.length; i++){
        if(value == array[i]) return true;
      }
      return false;
    },
    calculateContainerHeight () {
      const cart_items = this.$refs['cart-items']
      const cart_item = this.$refs['cart-item']
      const cart_header = this.$refs['cart-header']
      const visible_item = 10

      if (!(cart_items && cart_item && cart_header)) return

      if (!this.showAllOrder) {
        const cart_items_spliced = cart_item.splice(0, visible_item)
        const sum_height = cart_items_spliced.reduce((sum, item) => {
          if (cart_item.length <= 10) {
            return sum = item.clientHeight * visible_item
          }

          sum += (item.clientHeight)
          return sum
        }, 0)

        this.containerMaxHeight = `${sum_height + cart_header.clientHeight}px`
      }
    }
  },
};
</script>

<style scoped>
.modal_edit-search_result{
  position: relative;
  padding-top: 3px;
}
.modal_edit-header.loading .modal_edit-search_result{
  min-height: 50px;
}
.modal_edit-header .component-loading {
  background-size: 30px;
  background-color: transparent;
}
.b-content_cart .product-table img.t-prod-pic{
  height: unset;
  width: unset;
  max-height: 38px;
  max-width: 38px;
}
.resp-table-wrap{
  overflow: visible;
}
.modal_row{
	display:flex;
	margin-left:-15px;
	margin-right:-15px;
}
.modal_row .modal_col-6{
	padding-left:15px;
	padding-right:15px;
	width:50%;
}
.b-content__title{
	font-size:20px;
}
.b-content__subtitle{
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
}
.order-detail-params{
	padding:24px;
	background: #f6f8fb;
}
.order-detail-params .b-content__title{
	margin-bottom:20px;
}
.order-detail-params .control_text input,
.order-detail-params .control_text textarea {
    background: #FFFFFF;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 4px;
    height: 38px;
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Museo Sans Cyrl 300', sans-serif;
    padding: 10px 12px;
	margin-bottom: 8px;
}
.order-detail-params .control_text textarea {
    height: 129px;
}
.status-selector{
  border: 1px solid transparent;
  position: relative;
}
.status-selector .current-status{
  background: #F6F8FB;
  border-radius: 4px;
  line-height: 34px;
  display: block;
  width: 129px;
  padding: 0 12px;
  cursor: pointer;
  position: relative;
}
.status-selector .statuses-list{
  background: #FFF;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top:100%;
  margin-top:-2px;
  width:100%;
  left:0;
  display:none;
}
.status-selector.opened .statuses-list {
  display:block;
}
.status-selector .statuses-list .statuses-list-option{
  line-height: 34px;
  padding: 0 10px 0 34px;
  position: relative;
  cursor: pointer;
}
.status-selector .statuses-list .statuses-list-option .radio-icon{
  width: 16px;
  height: 16px;
  border: 1px solid #C4C4C4;
  border-radius: 50px;
  display: block;
  position: absolute;
  left:10px;
  top:50%;
  margin-top: -8px;
}
.status-selector .statuses-list .statuses-list-option.selected {
  font-family: 'Museo Sans Cyrl 700', sans-serif;
}
.status-selector .statuses-list .statuses-list-option.selected .radio-icon {
  background: #C4C4C4;
}
.status-selector .statuses-list .statuses-list-option.selected .radio-icon:after{
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3px;
  background: #fff;
  border-radius: 50%;
}
.status-selector .current-status svg{
  position: absolute;
  right: 14px;
  top: 50%;
  margin-top: -2px;
  transition: all 0.3s;
}
.status-selector.opened {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #E5E5E5;
}
.status-selector.opened .current-status svg {
  transform: rotate(180deg);
}
.order-detail .send_form{
  width:calc(100% - 250px);
  position:relative;
}
.order-detail .send_form .send_btn {
  position: absolute;
  right: 1px;
  top: 1px;
  background: #F6F8FB;
  line-height: 32px;
  padding: 0 20px;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: -0.408px;
  color: #52504C;
}
.order-detail .send_form input{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  font-size: 12px;
  line-height: 32px;
  padding: 0 12px;
}
.order-detail .btn_download{
  margin-right: 0!important;height:34px;
}
.order-send_result{
  padding-top: 15px;
  font-size: 14px;
}
.order-send_result.error{
  color:red;
}
#modal-edit-order{

}
#modal-edit-order .modal__inner{
  padding:0;
  position: relative;
  padding-bottom: 70px;

}
#modal-edit-order .modal__inner .modal_edit-container{
  overflow: auto;
}

#modal-edit-order .modal_edit-header{
  background: #F6F8FB;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 30px;
  position: relative;
  border-radius: 4px 4px 0 0;
  z-index: 1;
}
#modal-edit-order .modal_edit-footer{
  padding: 17px 30px;
  position: absolute;
  bottom: 0;
  left:0;
  width:100%;
  background: #FFFFFF;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#modal-edit-order .modal_edit-footer .total-amount{
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
#modal-edit-order .modal_edit-footer .total-amount b{
  font-size: 16px;
}

#modal-edit-order .modal_edit-header .modal_edit-search{
  width:100%;
  position: relative;
}
#modal-edit-order .modal_edit-header .modal_edit-search input{
  width:100%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  line-height: 32px;
  font-weight: 300;
  font-size: 12px;
  padding: 0 12px;
}
#modal-edit-order .modal_edit-header .modal_edit-search svg {
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}
#modal-edit-order .modal_edit-container{
  min-height: 300px;
  max-height: 400px;
}

.modal .product-grid::-webkit-scrollbar {
  width: 6px;
}
.modal .product-grid::-webkit-scrollbar-button {
  background: transparent;
  height: 0;
}

#modal-edit-order .modal_edit-container .modal_edit-order_data{
  background: #F6F8FB;
  display: flex;
}
#modal-edit-order .modal_edit-container .modal_col-4{
  padding: 12px 30px;
  width:33.33%;
}
#modal-edit-order .modal_edit-container .modal_col-8{
  padding: 12px 30px;
  width:66.66%;
}
#modal-edit-order .modal_edit-container .modal_edit-order_data h4{
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
#modal-edit-order .modal_edit-container .modal_edit-order_data label {
  font-weight: 300;
  font-size: 10px;
  line-height: 18px;
  align-items: flex-end;
  text-align: right;
  color: #52504C;
  display: block;
  margin-bottom: 0.5rem;
}
#modal-edit-order .modal_edit-container .modal_edit-order_data input {
  background: none;
  border: none;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #52504C;
}
#modal-edit-order .modal_edit-container .modal_edit-order_data textarea {
  background: #FFFFFF;
  border-radius: 4px;
  width:100%;
  border:none;
  padding: 7px 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #52504C;
  height: 66px;
}
.b-content_cart{
  padding: 30px;
}
</style>
