<template>
	<span v-if="nextPage" v-bind:class='itemData.pageClass' class="next_page"><span>{{itemData.name}}</span></span>
	<div v-else v-bind:class="[itemData.pageClass, itemClass]" class="animated ">
		<div class="product" v-bind:class="{'product_lite': itemData.arhive && !isCatalog && can_delete, 'product_lite product_deleted': itemData.deleted}">
			<div class="product__tools">
				<button v-on:click="$root.userItemsListsAction('favorite', id)" v-bind:class="{'is-active': $root.itemInUserList('favorite', id)}" class="js-fav" v-bind:data-pid=itemData.id title="Избранное">
					<svg width="18" height="18">
						<use xlink:href="/images/sprite.svg#star"></use>
					</svg>
				</button>
				<button v-on:click="$root.userItemsListsAction('compare', id)" v-bind:class="{'is-active': $root.itemInUserList('compare', id)}" class="js-comp" v-bind:data-pid=id title="Сравнить">
					<svg width="22" height="18">
						<use xlink:href="/images/sprite.svg#chart-bar"></use>
					</svg>
				</button>
			</div>
			<div class="product__header">
				<div v-if="itemData.ratio_info != ''" class="product__ratio_info">
					{{itemData.ratio_info}}
				</div>
				<div v-if="itemData.deleted" class="product__labels">
					<span class="label label_red">Удален из списка</span>
				</div>
				<div v-else class="product__labels">
					<span v-for="label in itemData.labels" v-bind:class="label.class" class="label" v-html="label.text"></span>
				</div>
				<div class="product__stock">
					<template v-if="this.itemData.can_buy">
						<span class="stock stock_green">В наличии</span>
						|
						<span class="stock">
							<span class="stock stock_link">на складах </span>
							<span class="stock stock_inf">&#9432;</span>
						</span>
						<div class="stock__hint--wrap">
							<div class="stock__hint">
								<div class="hint__item" v-for="item in this.itemData.amount">
									<span>{{ item.store_title }}</span>
									<span>{{ item.product_amount }}</span>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<span class="stock stock_red" >Нет в наличии</span>
						|
						<span class="stock">
							<span class="stock stock_link">на складах </span>
							<span class="stock stock_inf">&#9432;</span>
						</span>
					</template>
				</div>
				<template  v-if="!itemData.deleted">
					<button v-if="can_delete" v-on:click.prevent="userItemsListsAction" class="product__remove js-wlist-remove" title="Удалить из списка"></button>
				</template>
			</div>
			<div :to="detailPage" class="product__img">
				<router-link :to="detailPage">
					<img v-bind:src="itemData.image" alt="">
				</router-link>
				<button v-on:click="quickViewAction" class="btn product__quick-view js-quick-view-btn">Быстрый просмотр</button>
			</div>
			<div class="product__body" v-if="itemData.properties">
				<router-link :to="detailPage" class="product__art">Арт. {{ itemData.article }} <small>уп. {{itemData.properties.KOLICHESTVO_V_UPAKOVKAKH.value}} {{itemData.measure}}</small></router-link>
				<router-link :to="detailPage"div class="product__name" v-html="itemData.name"></router-link>
			</div>

			<div class="product__footer">
				<div class="product__price">
					<div class="product__stock">
						<template v-if="this.itemData.can_buy">
							<span class="stock stock_green">В наличии</span>
							<span class="stock">
								<span class="stock stock_link">на складах </span>
								<span class="stock stock_inf">&#9432;</span>
							</span>
							<div class="stock__hint--wrap">
								<div class="stock__hint">
									<div class="hint__item" v-for="item in this.itemData.amount">
										<span>{{ item.store_title }}</span>
										<span>{{ item.product_amount }}</span>
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<span class="stock stock_red">Нет в наличии</span>
							<span class="stock">
								<span class="stock stock_link">на складах </span>
								<span class="stock stock_inf">&#9432;</span>
							</span>
						</template>
					</div>
					<template v-if="itemData.can_buy">
						<span v-if="priceData.print_old" v-html="priceData.print_old" class="old-price"></span>
						<span v-html="priceData.print" class="price"></span>
					</template>
					<template v-else>
						<span v-html="priceData.print" class="last-price"></span>
					</template>
				</div>
				
				<template  v-if="!itemData.basketDisabled">
					<template  v-if="itemData.deleted">
						<div class="product__form">
							<form class="js-product-form" action="">
								<button v-on:click.prevent="userItemsListsAction" type="button" class="btn btn__analogs">Восстановить</button>
							</form>
						</div>
					</template>
					<template v-else>
						<div v-if="this.itemData.can_buy" class="product__form">
							<form class="js-product-form" action="">
								<input type="hidden" name="pid" v-bind:value="itemData.id" />
								<div class="counter js-counter">
									<button @click="counterMinus" type="button" class="btn decrease">-</button>
									<input class="count" type="text" name="quantity" :value="animatedNumber" autocomplete="off" v-on:input="counterInput"/>
									<button @click="counterPlus" type="button" class="btn increase">+</button>
								</div>
								<router-link v-if="$root.itemInUserList('basket', id)" :to="'/cart/'" class="btn btn_transp btn__submit">Добавлено</router-link>
								<button v-else v-on:click.prevent="$root.userItemsListsAction('basket', id, curCount)" type="submit" name="action" value="buy" class="btn btn__submit">В корзину</button>
							</form>
						</div>
						<div v-else class="product__form">
							<form class="js-product-form" action="">
								<button type="button" v-on:click="analogsAction" v-bind:class="{'disabled': itemData.hide_analogs}" class="btn btn__analogs js-modal-analogs">Аналоги</button>
								<template v-if="showSubscribe">
									<router-link v-if="$root.itemInUserList('waiting', id)" :to="'/waiting/'" class="btn btn_transp btn__submit">В листе ожидания</router-link>
									<button v-else type="submit" v-on:click.prevent="$root.userItemsListsAction('waiting', id)" value="waiting-list" name="action" class="btn btn__submit">В лист ожидания</button>
								</template>
							</form>
						</div>
					</template>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'listItemComponent',
	props: {
		id: 0,
		can_delete: false,
	},
	data() {
		return {
			itemData: {},
			priceData: {},
			arUser: this.$root.arUser,
			isCatalog: this.$root.isCatalog,
			inited: false,
			nextPage: false,
			maxCount: 0,
			curCount: 1,
			tweenedNumber: 1,
			detailPage: "",
			timer: null,
			itemClass: "product-grid__item",
		};
	},
	computed: {
		listType() { return this.$route.name || false },
		deletedListItems() { return this.$root.deletedListItems[this.listType] || false },
		navPageNum() {return this.$parent.navPageNum || 1},
		animatedNumber: function() { return this.tweenedNumber.toFixed(0); },
		showSubscribe(){
			var self = this;
			if(self.$route.meta.hideSubscribe === true) return false;
			else if(self.itemData.arhive) return false;
			else return true;
		},
	},
	watch: {
		deletedListItems: function(newValue, oldValue) {
			var self = this;
			if(!self.isCatalog && self.listType !== false){
				var productIndex = newValue.findIndex(x => x == self.id);
				self.itemData.deleted = productIndex >= 0;
			}
		},
		curCount: function(newValue, oldValue) {
			var self = this;
			gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue });
			
			if(self.inited){
				if(newValue == 0){
					clearTimeout(this.timer);
					self.$root.userItemsListsAction("basket", self.id, newValue);
					setTimeout(function(){
						self.curCount = parseFloat(self.itemData.ratio);
					}, 500);
				}else{
					
				}
			}
		}
	},
	methods: {
		counterInput: function(e){
			var self = this,
				inputVal = e.target.value;
			
			if(!!self.timer) clearTimeout(this.timer);
			
			self.timer = setTimeout(function(){
				self.curCount = inputVal;
				
				self.$nextTick(function () {
					var ratio = parseFloat(self.itemData.ratio);
					var newValue = Math.ceil(inputVal/ratio)*ratio;
					if(newValue > self.maxCount) newValue = self.maxCount;
					self.curCount = newValue;
					self.$root.userItemsListsAction("basket", self.id, self.curCount);
				})
				
			}, 1000);
		},
		counterPlus: function(){
			var self = this;
			var newValue = self.curCount + parseFloat(self.itemData.ratio);
			if(newValue > self.maxCount) newValue = self.maxCount;
			self.curCount = newValue;
			
			if(self.$root.itemInUserList('basket', self.id)){
				if(!!self.timer) clearTimeout(this.timer);
				self.timer = setTimeout(function(){
					self.$root.userItemsListsAction("basket", self.id, self.curCount);
				}, 300);
			}
		},
		counterMinus: function(){
			var self = this;
			var newValue = self.curCount - parseFloat(self.itemData.ratio);
			if(newValue <= 0) newValue = 0;
			self.curCount = newValue;
			
			if(self.$root.itemInUserList('basket', self.id)){
				if(!!self.timer) clearTimeout(this.timer);
				self.timer = setTimeout(function(){
					self.$root.userItemsListsAction("basket", self.id, self.curCount);
				}, 300);
			}
		},
		analogsAction: function(){
			this.$root.analogsAction(this.itemData);
		},
		quickViewAction: function(){
			this.$root.quickViewAction(this.id);
		},
		userItemsListsAction: function(){
			if(this.listType) this.$root.userItemsListsAction(this.listType, this.id);
		},
	},
	beforeDestroy(){
		if(this.listType) this.$root.deletedListItems[this.listType] = [];
	},
	mounted: function(){
		var self = this;
		
		self.itemData = self.$attrs;
		self.priceData = self.itemData.ratio_price;
		if(self.$attrs.itemClass) self.itemClass = self.$attrs.itemClass;
		
		if(self.arUser.basket.ids_product && self.arUser.basket.ids_product[self.id] != undefined && app.arUser.basket.ids_product[self.id] != null){
			self.curCount = parseFloat(self.arUser.basket.ids_product[self.id]);
		}else{
			self.curCount = parseFloat(this.itemData.ratio);
		}
		self.detailPage = self.itemData.link;
		
		self.nextPage = self.itemData.pageClass !== "" && self.navPageNum > 1;
		self.maxCount = parseInt(self.itemData.product.QUANTITY);	
		
		self.inited = true;
		
		
		var cats = self.arUser.fields.UF_CATS;
		if ( cats.indexOf( 'i:2;s:1:"N";' ) !== -1 )
		{
			self.itemData.basketDisabled = true;
		}
	},
};
</script>

<style scoped>
.product__img>a{
	display: flex;
    width: 100%;
    height: 100%;
}
.product{
	transition: all 0.5s ease;
}

.product__body{
	transition: padding 0.5s ease;
} 
.product-grid.list-mode .product__img, .product-grid .product__img,
.product-grid.list-mode .product__img, .product-grid .product__form,
.product-grid.list-mode .product__footer, .product-grid .product__footer{
	transition: all 0.3s ease;
}
.product-grid.list-mode .product__tools, .product-grid .product__tools,
.product-grid.list-mode .product__tools, .product-grid .product__price{
	transition: all 0s ease 0.3s;
}
.product-grid.list-mode .product_lite.product_deleted .product__img, .product-grid .product_lite.product_deleted .product__img{
    width: 0;
    height: 0;
    display: flex;
	opacity:0;
	flex: 0;
}
.product-grid.list-mode .product_lite.product_deleted .product__tools, .product-grid .product_lite.product_deleted .product__tools{
    width: 0;
    height: 0;
    display: flex !important;
	opacity:0;
	flex: 0;
	transition: all 0s ease;
}
.product-grid.list-mode .product_lite.product_deleted .product__price, .product-grid .product_lite.product_deleted .product__price{
    width: 0;
    height: 0;
    display: none !important;
    opacity: 0;
    transition: all 0s ease;
    margin: 0;
    padding: 0;
}
.product-grid.list-mode .product_lite.product_deleted, .product-grid .product_lite.product_deleted{
    padding: 4px 11px 4px 22px;
}
.product-grid.list-mode .product_lite.product_deleted  .product__header .label.label_red, 
.product-grid .product_lite.product_deleted  .product__header .label.label_red{
    color: #FF0000;
    border-color: #FF0000;
}
.next_page{  
	width: 100%;
    display: block;
    text-align: center;
    font-size: 12px;
    font-family: 'Museo Sans Cyrl 700', sans-serif;
	margin-top: 6px;
    margin-bottom: 6px;
	position: relative;
}
.next_page:after{ 
    content: "";
    position: absolute;
    width: 96%;
    border-bottom: 1px solid #E5E5E5;
    top: 50%;
    left: 2%;
    z-index: 0;
}
.next_page span{  
    padding: 4px 10px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    z-index: 1;
    position: relative;
}
.product-grid .product__img, .similar-products .product__img{
    height: 150px;
    display: flex;
}
.counter .count{
	min-width: 35px;
}
@media screen and (min-width: 768px){
	.product-grid.list-mode .product__img {
		height: 98px;
	}
	.product-grid.list-mode .product__img img{
		width: auto;
		max-height: 90%;
	}
}
.product__ratio_info{
    top: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 10px;
    color: #52504C;
    display: block;
    position: absolute;
    right: 0;
}
.grid-mode .product__ratio_info,
.owl-item .product__ratio_info{
	left: 15px;
    top: 45px;
    z-index: 10;
}
.product-grid .product__price .price{
	position:relative;
}
.product__art small{
	font-family: 'Museo Sans Cyrl 100', sans-serif;
	margin-left: 20px;
}
</style>
